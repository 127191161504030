import React, { useState, useEffect, useCallback } from "react";
import "./STBpayment.css";
import tvlogo from "./../images/tvicon.png";
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi from "../services/authApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";

import Loader from "react-loader-spinner";
import swal from "sweetalert";
function Subcription() {
  const history = useHistory();

  var [isButtonLoading, setButtonLoading] = useState(false);
  let getCompanyName = localStorage.getItem("companyName");
  let getCustomerId = localStorage.getItem("customerID");
  let getCustomerName = localStorage.getItem("customerName");
  let amount = localStorage.getItem("dueAmount");
  let min_condition=localStorage.getItem("min_condition")
  const [editableAmount, setEditableAmount] = useState(amount); 
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [btnVisibility, setbtnVisibility] = useState("visible");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAmountChange = (e) => {
    let msg="Error: Amount must between "+min_condition+" and "+amount;
    let value = e.target.value;
    if(!isNaN(value) && (value === "" || Number(value)<Number(min_condition))){
      setEditableAmount(value);
      setError(Number(value) <min_condition);
      setErrorMsg(msg);
      
      
      setbtnVisibility("hidden");
    }
    else if(!isNaN(value) && (value === "" || Number(value)>Number(amount))){
      setEditableAmount(value);
      setError(Number(value) >amount);
      setErrorMsg(msg);
      
      setbtnVisibility("hidden");
    }
    else{
      setError(false);
      setEditableAmount(value);
      setbtnVisibility("visible");
    }
  };
 
  const onSignIn = async () => {
    //  alert(localStorage.getItem("ownerId"));
    setButtonLoading(true);
    if (amount < 1) {
      setButtonLoading(false);
      alert("Amount should be greater than (\u20B9) 1.00");
    } else {
      setButtonLoading(true);
      let newDate = new Date();
      let getYear = newDate.getFullYear().toString();
      let getMonth = newDate.getMonth().toString();
      let getDate = newDate.getDate().toString();
      let getMSec = newDate.getMilliseconds().toString();
      let combineDate = getYear + getMonth + getDate + getMSec;

      let tranRef =
        "RSPT" + localStorage.getItem("customerNumber") + combineDate;
      console.log("tranRef njkskjf bsdfbkb sbfsdf");
      console.log(tranRef);
      // let tran =
      //   "TRNNXTSUBWEB" + parseInt(localStorage.getItem("ownerId")) + Date.now();
      let data = JSON.stringify({
        AMOUNT: editableAmount,
        PHONE: localStorage.getItem("phoneNumber"),
        OPERATOR_ID: localStorage.getItem("ownerID"),
        flag: "A",
        CUST_NUM: localStorage.getItem("customerNumber"),
        customerName: localStorage.getItem("customerName"),
        tran_id: tranRef,
      });
      console.log(data);
      await AuthApi.cashfree_init(data)
        .then((response) => {
          console.log("test");
          console.log(response);
          if (response.data.p_out_mssg_flg === "S") {
            window.location.href =
              "https://apps.mobiezy.in/CASHFREE/REACT_MPAY/cashfree_ui.php?paymentSessionId=" +
              response.data.payment_session_id;
          } else {
            setButtonLoading(false);

            swal("Error", "response.data.p_out_mssg", "warning");
          }
        })
        .catch((e) => {
          setButtonLoading(false);
          console.log(e);
        });
      // window.location.href = "https://mobicollector.com/cashfree/checkout.php?PHONE=9989839318&amount=1&name=Test&op_id=8488&tran_id=TRNNXTSUB84881651049226249"
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-start" alignItems="flex-start">
          <IconButton
            style={{ paddingRight: "30px" }}
            color="inherit"
            onClick={() => history.push("/dashboard")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton
            deleteIcon3
            size="large"
            style={{ padding: "3px", paddingRight: "15px", color: "#ff8c00" }}
          >
            <Help HelpIcon />
          </IconButton>
        </Grid>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} width="140" height="140" />
        <div style={{ paddingTop: "25px", paddingLeft: "45px" }}>
          <h4>{getCompanyName}</h4>
          <h5>ID : {getCustomerId}</h5>
          <h5>Name : {getCustomerName}</h5>
        </div>
      </div>
      <h4 style={{ color: "#ff8c00", paddingLeft: "5px", paddingTop: "5px" }}>
        <hr></hr>Billing Details({"\u20B9"}) <hr></hr>
      </h4>
      <div id="textbox">
        <h5 class="alignleft">Status</h5>
        <h5 class="alignright" style={{ color: "green" }}>
          Active
        </h5>
      </div>

      {/* <div id="textbox"><hr></hr>
  <h5 class="alignleft">Month</h5>
  <h5 class="alignright">Feb-2021</h5>
  
</div> */}

<div id="textbox">
      <hr />
      <h5 className="alignleft">Amount Due</h5>
      <h5 className="alignright" style={{ color: "red" }}>
      {"\u20B9"}{" "}
      <input
  type="number"
  value={editableAmount}
  onChange={handleAmountChange}
  disabled={localStorage.getItem("editPayment_enabled") === "N"} // Correct boolean conversion
  style={{
    color: "red",
    fontSize: "inherit",
    width: "75px",
    textAlign: "right",
  }}
/>

{" "}
        /-
      </h5>
  
    </div>



      <center>
        <hr></hr> &nbsp;{" "}
        <h4>
          Total Payable Amount : {"\u20B9"} {amount} /-
        </h4>
        {error && <span style={{ color: "red", fontSize: "17px" }}>{errorMsg}</span>}
        {isButtonLoading === true ? (
          <center>
            <Loader type="TailSpin" color=" #ff8c00" height="70" width="70" />
          </center>
        ) : (
          
          <button
            style={{
              color: "white",
              backgroundColor: "#ff8c00",
              width: "200px",
              textDecoration: "none",
              visibility:btnVisibility
            }}
            type="submit"
            className="btn btn-lg btn-block" 
            onClick={
              () => onSignIn()
              //(window.location.href = "/cashfree")
              // localStorage.getItem("razorpayFlag") === "Y"
              //   ? generaterazorpay()
              //   : generateTraknpay()
            }
          >
            Proceed to Pay{" "}
          </button>
        )}
        &nbsp;{" "}
      </center>
    </React.Fragment>
  );
}

export default Subcription;
